// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://eauth74.libresolucoes.com.br',
  api: 'https://api.eauth74.libresolucoes.com.br',
  clientId: 1,
  clientSecret: 'zjF1a1lneaJjvGB9vnrlNE2JAObvx9pUcSb1twSn',
  recaptchaSiteKey: '6LeoUAkqAAAAAKFxJGLQZkCMkr2JxQpMVkufzxU9',
  eStorageUrl: 'https://estorage74.libresolucoes.com.br',
  eStorageId: '1',
  eStorageSecret: 'sF4jiWhcOMhHrkHKitDvcJdFJM45CGhayDL2Vb1g'
};

/*
 * 5,8,9,16,19,10,
For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
